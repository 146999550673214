import React from 'react'
import image1 from '../../../assets/images/story/miro/miro-1.png'
import image2 from '../../../assets/images/story/miro/miro-2.png'
import image3 from '../../../assets/images/story/miro/miro-3.png'
import { InlineQuote } from '../../blocks/InlineQuote'
import Content from '../Content'
import { ProductScreenshot } from '../ProductScreenshot'

const MiroBody = () => {
  return (
    <Content
      sidebar={{
        company: (
          <p>
            Miro is an innovation workspace that enables distributed teams of
            any size to build the next big thing.
          </p>
        ),
        href: 'https://miro.com',
        location: (
          <p>
            Co-headquartered in Amsterdam and San Francisco, global operations
          </p>
        ),
        headcount: <p>1,800+ employees, 500+ in engineering</p>,
        customers: <p>2024</p>,
      }}
      main={
        <>
          <p>
            The Engineering Productivity team at Miro is dedicated to creating a
            data-driven engineering discipline and enabling leaders on all
            levels to optimize processes across the company’s globally
            distributed engineering organization. Miro’s engineering culture is
            based on autonomy with its freedom-within-a-frame principle, where
            each team has the right to make decisions within their control but
            are also offered guidance on what needs to be in place to glue the
            Miro way-of-working together.
          </p>
          <p>
            The Engineering Productivity team was serving a set of metrics to
            Miro’s 100+ engineering teams. While some of the metrics
            representing feature adoption and quality were easier to surface,
            the team was struggling to provide reliable, up-to-date, meaningful,
            and sufficiently granular data on the speed of software development.
          </p>
          <InlineQuote
            quote="Collecting and sanitizing data from engineering tools like GitHub and Jira is a continuous effort for which we had limited capacity. Frequent changes and malfunctions in the data source APIs were consuming our resources."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <p>
            The Engineering Productivity team quickly realized that building
            dashboards of widely adopted yet continuously evolving metrics, like
            DORA and SPACE, was not worth the investment.
          </p>
          <InlineQuote
            quote="Initially, we started looking into solutions like Swarmia because we found ourselves building commodity metrics like DORA and SPACE — and facing the complexity that comes with it. Building and maintaining them in-house is a waste of capacity because there are specialized vendors dedicated to delivering them."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <p>
            In addition to the cost of keeping the data in sync and the quality
            high, the engineering teams at Miro wanted a tool that would allow
            them to interact with the data to enable root cause analysis on
            potential improvement opportunities within essential engineering
            processes and allow teams to drive actual change. The existing
            dashboard was not offering this functionality, which was hindering
            concrete improvements. Digging deep into the data — from high-level
            trend analysis all the way to actual source datasets — was critical
            to the teams because it would give them the detail they needed to
            improve engineering process flows.
          </p>
          <p>
            Furthermore, Miro’s existing tech stack didn’t offer a customizable
            notification system for engineers or teams. This resulted in a pull
            model, where engineers needed to build a habit of regularly checking
            in on key metrics rather than getting relevant data delivered to
            them.
          </p>

          <h2>Bringing unified data to everyone’s fingertips</h2>
          <p>
            Before starting the vendor selection process, the Engineering
            Productivity team worked with the teams to improve their Jira
            hygiene, rolled out unified naming conventions for key elements in
            the software development lifecycle, and increased data consistency
            on a global level. While it was important that the teams would
            continue to make their own decisions, the Engineering Productivity
            team set some ground rules for maintaining data quality going
            forward.
          </p>
          <p>
            When Walter and his team were happy with the data quality
            improvements and convinced they wouldn’t face a garbage-in,
            garbage-out situation, they invited a pilot group of engineering
            managers, senior engineering managers, and a member of the
            Engineering Leadership team to meet with three shortlisted vendors.
          </p>
          <p>
            After an introductory meeting and a demo with each vendor, they ran
            a survey with the pilot group, which resulted in Swarmia getting the
            highest outcome scores and the pilot group’s consensus that it would
            be able to address the key challenges Miro had identified. Most
            importantly, Swarmia allowed Miro to hold on to their
            freedom-within-a-frame principle without compromising data quality.
          </p>
          <InlineQuote
            quote="From the start, we aligned with Swarmia in how we both see that data should be empowering leaders so they can lead, instead of dictating how teams should work."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <h3>Powering internal dashboards with data from Swarmia</h3>
          <p>
            Around the same time Miro started trialing Swarmia, they also
            introduced an instrument called the Five Metrics. It consists of a
            combination of customer-centric metrics related to quality and
            support (bug resolution time and follow up on service requests),
            capacity spend (the Balance Framework), and the speed of delivery
            (deployments and pull request cycle time).
          </p>
          <p>
            However, instead of sending hundreds of engineers to separate
            platforms to find each of these metrics, Walter and his team
            consolidated the Five Metrics into their data lake that feeds it
            into a shared dashboard, giving everyone a single entry point into
            the most important, highest-level metrics. In this system, Swarmia
            acts as a key provider of engineering related metrics.
          </p>
          <InlineQuote
            quote="We recognize that you can also drown in your own data. Bringing tools that give a lot of insights may not bring the progress you want because there’s too much to look at."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <p>
            In addition to limiting the Miro engineers’ attention to the metrics
            that matter, the layered approach allows them to drill deeper into
            any specific areas that may interest them. On a high level, the
            reporting structure works as follows:
          </p>
          <ul>
            <li>
              Layer 1: A dashboard that acts as a single point of entry to the
              Five Metrics
            </li>
            <li>
              Layer 2: Supporting metrics for each of the Five Metrics to allow
              quick analysis in the dashboard (e.g. showing throughput side by
              side with pull request cycle time)
            </li>
            <li>
              Layer 3: Root cause analysis with links from the main dashboard to
              specific views in Swarmia, with the right filters as presets to
              save time and error
            </li>
          </ul>
          <ProductScreenshot src={image1} />
          <InlineQuote
            quote="Swarmia allows us to export, reuse, and unify the data with the rest of our metrics. This means our whole engineering organization can look at the same data and ensures we’re all talking about the same things."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <h3>Balancing engineering investments</h3>
          <p>
            In a large engineering organization like Miro, it can be difficult
            to get visibility into where engineering capacity goes: Are all the
            strategic initiatives staffed appropriately? Are the product teams
            able to spend enough time on improving existing features? Are some
            teams struggling to keep the existing software operational?
          </p>
          <InlineQuote
            quote="We recommend that all teams look into their investment balance. It helps us answer questions like, are we pushing hard enough on new products or improving existing products? Do we see patterns where keeping the lights on work is becoming an excessive capacity requirement to a team?"
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <ProductScreenshot src={image2} />
          <p>
            Even though Miro already had a solution in place for tracking
            engineering investments before Swarmia, they appreciate Swarmia’s
            ability to customize the investment categories and create multiple
            ways to slice and dice the data.
          </p>
          <InlineQuote
            quote="Swarmia doesn’t force us to use a specific investment categorization framework. Instead, we can configure it to split the work exactly how we want. It’s an important instrument that helps us keep an eye on the ball and understand where we’re spending our capacity."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <h3>
            Moving from a pull to a push model in delivering insights to
            engineers
          </h3>
          <p>
            As a part of their efforts to extend Miro’s data-driven engineering
            culture to every corner of the organization, the Engineering
            Productivity team wanted to start pushing relevant data to the teams
            rather than expecting them to spend time searching for the data they
            were interested in.
          </p>
          <p>
            Teams were encouraged to adopt Swarmia’s working agreements and
            Slack notifications to introduce a push model. The idea is that
            teams set their own agreements and get both real-time and scheduled
            Slack notifications corresponding to the targets they’ve set for
            themselves.
          </p>
          <ProductScreenshot src={image3} />
          <InlineQuote
            quote="The most loved Swarmia feature for our engineers is the Slack notifications. They’re much more actionable than GitHub’s native ones. They also automatically make our engineers aware if something is off, so they can start putting effort into improving it."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />

          <h2>Building trust in the data</h2>
          <p>
            After the first few months of using Swarmia, Walter could already
            sense a change in the way the teams were consuming and using data in
            their daily work.
          </p>
          <p>
            The engineers at Miro knew from experience that building data
            pipelines takes a lot of effort, and failing to factor in important
            nuances can completely break trust in the data. However, the more
            they explored Swarmia and the dashboards it powered, the more they
            started relying on the insights it provided and taking advantage of
            it in their work.
          </p>
          <InlineQuote
            quote="The first reaction from our engineers was that ‘this data can’t be right.’ But because Swarmia allows you to drill down into the raw data behind every aggregate, it’s easy to prove that it is, in fact, correct. In a short time, our engineers gained trust in the data by regularly looking at their own data points."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <p>
            In fact, what started out as a 25-person trial organically expanded
            into 100 engineers trying out the platform as they kept hearing
            about it from their coworkers. To further prove Swarmia’s value to
            everyone in the organization, the Miro Engineering Leadership team
            invited a software engineer to demo his real-life Swarmia use cases
            to them.
          </p>
          <p>
            Walter, on the other hand, was relieved that the engineering
            analysts in his team no longer have to spend time on building and
            maintaining data pipelines for pulling commodity metrics like DORA
            into their dashboard. Instead, they can now get this data from
            Swarmia and spend time on more meaningful and value-adding
            activities, like data analysis.
          </p>
          <InlineQuote
            quote="When it comes to DORA or SPACE related metrics we can now spend our time making them effective by driving insights and change, instead of first building and then maintaining them."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <p>
            Overall, Walter and the engineering teams at Miro are happy about
            the close partnership they’ve built with Swarmia and the speed at
            which they’re able to move things forward.
          </p>
          <InlineQuote
            quote="We have a shared Slack channel with our Swarmia customer success manager, some of the engineers, and product managers. They’re quick to react to any of our questions and requests, which makes the ongoing collaboration easy."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
          <p>
            While the Engineering Productivity team acknowledges that completing
            the rollout of any new platform in such a large organization will
            take a while, they’ve been positively surprised about the speed of
            Swarmia adoption. Miro’s layered approach to reporting gives
            everyone in the engineering organization access to the data they
            need, whether it’s the high-level metrics in the dashboard or the
            more granular data in Swarmia.
          </p>
          <InlineQuote
            quote="Swarmia both feeds and empowers our insights. It allows our engineers to dive deeper into the metrics in a specialized environment, which would make no sense for us to build for ourselves."
            name="Walter de Bruijn"
            title="Head of Engineering Productivity"
          />
        </>
      }
    />
  )
}
export default MiroBody
